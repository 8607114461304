import { coreServices } from '@/services/coreServices';

export const masterdataServices = {
  getMasterdata() {
    return coreServices.get('/masterdata/data/', coreServices.getRequestHeader(), '');
  },
  getDefaultKpiDays() {
    return coreServices.get('/masterdata/default-kpi/', coreServices.getRequestHeader());
  },
};
